import { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { ethers } from "ethers";

// Navbar
import Navbar from "react-bootstrap/Navbar";
import logo from "../binarybit-logo.svg";

// ABIs
import WBNRY_ABI from "../abis/WBNRY.json";
import STAKING_ABI from "../abis/Staking.json";

// Config
import config from "../config.json";

function App() {
  const [provider, setProvider] = useState(null);
  const [staking, setStaking] = useState(null);
  const [wbnry, setWBNRY] = useState(null);

  // State variables for contract data
  const [wbnryAddress, setWBNRYAddress] = useState(null);
  const [wbnrySupply, setWBNRYSupply] = useState(null);
  const [stakingAddress, setStakingAddress] = useState(null);
  const [totalStaked, setTotalStaked] = useState(null);
  const [totalStakers, setTotalStakers] = useState(null);
  const [totalTreasuryTokens, setTotalTreasuryTokens] = useState(null);
  const [annualYield, setAnnualYield] = useState(null);

  const [account, setAccount] = useState(null);
  const [accountWBNRYBalance, setAccountWBNRYBalance] = useState(null);
  const [accountStake, setAccountStake] = useState(null);
  const [accountGains, setAccountGains] = useState(null);
  const [accountStakeBalance, setAccountStakeBalance] = useState(null);

  const [stakeAmount, setStakeAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [isLoading, setIsLoading] = useState(null);

  const TARGET_NETWORK_ID = "56"; // BSC Testnet network ID = 97, mainnet = 56, hardhat = 31337

  const normalizeInput = (input) => {
    const normalized = input.replace(",", ".");
    const validInput = normalized.match(/^\d+(\.\d{1,8})?$/);
    if (!validInput) {
      throw new Error("Invalid input");
    }
    return ethers.utils.parseUnits(normalized, 8);
  };

  const loadDefaultData = async () => {
    try {
      const defaultProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );

      const { chainId } = await defaultProvider.getNetwork();

      // Initiate contracts
      const WBNRY = new ethers.Contract(
        config[chainId].WBNRY.address,
        WBNRY_ABI,
        defaultProvider
      );
      const Staking = new ethers.Contract(
        config[chainId].Staking.address,
        STAKING_ABI,
        defaultProvider
      );

      // Fetch contract information and update state
      const wbnrySupply = await WBNRY.totalSupply();
      const totalStaked = await Staking.totalTokensStaked();
      const totalTreasuryTokens = await Staking.totalTreasuryTokens();
      const annualYield = await Staking.annualYield();

      setWBNRYAddress(WBNRY.address);
      setWBNRYSupply(wbnrySupply);
      setStakingAddress(Staking.address);
      setTotalStaked(totalStaked);
      setTotalStakers((await Staking.totalStakers()).toNumber());
      setTotalTreasuryTokens(totalTreasuryTokens);
      setAnnualYield(annualYield.toString());

      // Set the contract instances to state
      setStaking(Staking);
      setWBNRY(WBNRY);
    } catch (error) {
      console.error("Error loading default data:", error);
    }
  };

  const loadUserData = async () => {
    try {
      console.log("triggered loadUserData");

      // Initiate provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider);

      // Fetch Chain ID
      const { chainId } = await provider.getNetwork();

      if (chainId.toString() !== TARGET_NETWORK_ID) {
        alert(
          `Please connect to the correct network. Current Network ID: ${chainId}, Required Network BNB Chain, ID: ${TARGET_NETWORK_ID}`
        );
        setIsLoading(false);
        return;
      }

      // Initiate contracts
      const WBNRY = new ethers.Contract(
        config[chainId].WBNRY.address,
        WBNRY_ABI,
        provider
      );
      const Staking = new ethers.Contract(
        config[chainId].Staking.address,
        STAKING_ABI,
        provider
      );
      setStaking(Staking);
      setWBNRY(WBNRY);

      // Initiate accounts
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = ethers.utils.getAddress(accounts[0]);
      setAccount(account);

      // Fetch account balance
      const accountWBNRYBalance = ethers.utils.formatUnits(
        await WBNRY.balanceOf(account),
        8
      );
      setAccountWBNRYBalance(accountWBNRYBalance);

      const participant = await Staking.getParticipant(account);

      const currentBalance = await Staking.calculateCurrentBalanceCompound(
        account
      );

      const gains = currentBalance.sub(participant.directStakeAmountSatoshi);

      // Format the values correctly
      const formattedCurrentBalance = ethers.utils.formatUnits(
        currentBalance,
        8
      );
      const formattedGains = ethers.utils.formatUnits(gains, 8);

      setAccountStake(
        ethers.utils.formatUnits(participant.directStakeAmountSatoshi, 8)
      );
      setAccountStakeBalance(formattedCurrentBalance);
      setAccountGains(formattedGains);

      setIsLoading(false);
    } catch (error) {
      console.error("Error loading user data:", error);
      setIsLoading(false);
    }
  };

  const handleStakeMax = async () => {
    if (!staking || !wbnry || !account) return;

    try {
      const userBalance = await wbnry.balanceOf(account);
      const userApproval = await wbnry.allowance(account, staking.address);

      console.log("userBalance:", ethers.utils.formatUnits(userBalance, 8));
      console.log("userApproval:", ethers.utils.formatUnits(userApproval, 8));

      if (userBalance.isZero()) {
        alert("You have no WBNRY to stake");
        return;
      }

      if (userApproval.lt(userBalance)) {
        const approvalTx = await wbnry
          .connect(provider.getSigner())
          .approve(staking.address, userBalance);
        await approvalTx.wait();
      }

      const gasPrice = await provider.getGasPrice();
      const gasLimit = await staking
        .connect(provider.getSigner())
        .estimateGas.stake(userBalance);

      const stakeTx = await staking
        .connect(provider.getSigner())
        .stake(userBalance, { gasLimit: gasLimit.mul(3) });
      await stakeTx.wait();

      alert("Staking successful!");

      await loadDefaultData();
      await loadUserData();
    } catch (error) {
      console.error("Staking failed:", error);
      alert("Staking failed! Check the console for more details.");
    }
  };

  const handleStake = async (event) => {
    event.preventDefault();

    if (!staking || !wbnry || !account || !stakeAmount) return;

    try {
      const amountToStake = normalizeInput(stakeAmount);
      const userBalance = await wbnry.balanceOf(account);
      const userApproval = await wbnry.allowance(account, staking.address);
      console.log("amountToStake:", amountToStake.toString());
      console.log("userBalance:", userBalance.toString());
      console.log("userApproval:", userApproval.toString());

      if (userBalance.lt(amountToStake)) {
        alert("Insufficient token balance");
        return;
      }

      if (userApproval.lt(amountToStake)) {
        console.log("Approval required, sending approval transaction...");
        const approvalTx = await wbnry
          .connect(provider.getSigner())
          .approve(staking.address, amountToStake);
        await approvalTx.wait();
        console.log("Approval transaction hash:", approvalTx.hash);

        const newUserApproval = await wbnry.allowance(account, staking.address);
        console.log("New userApproval:", newUserApproval.toString());

        if (newUserApproval.lt(amountToStake)) {
          alert("Approval failed or insufficient approval amount");
          return;
        }
      }

      const finalApproval = await wbnry.allowance(account, staking.address);
      console.log("Final approval before staking:", finalApproval.toString());

      if (finalApproval.gte(amountToStake)) {
        const gasPrice = await provider.getGasPrice();
        const gasLimit = await staking
          .connect(provider.getSigner())
          .estimateGas.stake(amountToStake);
        console.log("Proceeding to stake...");

        console.log("Attempting to stake amount:", amountToStake.toString());
        console.log("Gas Price:", (await provider.getGasPrice()).toString());
        console.log(
          "Gas Limit:",
          (
            await staking
              .connect(provider.getSigner())
              .estimateGas.stake(amountToStake)
          ).toString()
        );

        console.log("Proceeding to stake...");
        const stakeTx = await staking
          .connect(provider.getSigner())
          .stake(amountToStake, { gasLimit: gasLimit.mul(3) });
        await stakeTx.wait();
        console.log("Staking transaction hash:", stakeTx.hash);

        alert("Staking successful!");

        await loadDefaultData();
        await loadUserData();

        setStakeAmount("");
      } else {
        alert("Final approval check failed");
      }
    } catch (error) {
      console.error("Staking failed:", error);
      alert("Staking failed! Check the console for more details.");
    }
  };

  const handleWithdraw = async (event) => {
    event.preventDefault();

    if (!staking || !account || !withdrawAmount) return;

    try {
      const amountToWithdraw = normalizeInput(withdrawAmount);

      const gasEstimate = await staking
        .connect(provider.getSigner())
        .estimateGas.withdraw(amountToWithdraw);
      console.log("Estimated Gas: ", gasEstimate.toString());

      const withdrawTx = await staking
        .connect(provider.getSigner())
        .withdraw(amountToWithdraw, {
          gasLimit: gasEstimate.mul(2),
        });
      await withdrawTx.wait();

      alert("Withdrawal successful!");

      // Reload variables
      await loadDefaultData();
      await loadUserData();
    } catch (error) {
      console.error("Withdrawal failed:", error);
      alert("Withdrawal failed! Check the console for more details.");
    }
  };

  const handleWithdrawMax = async () => {
    if (!staking || !account) return;

    try {
      const currentBalance = await staking.calculateCurrentBalanceCompound(
        account
      );

      if (currentBalance.isZero()) {
        alert("You have no WBNRY to withdraw");
        return;
      }

      const gasEstimate = await staking
        .connect(provider.getSigner())
        .estimateGas.withdraw(currentBalance);
      console.log("Estimated Gas: ", gasEstimate.toString());

      const withdrawTx = await staking
        .connect(provider.getSigner())
        .withdraw(currentBalance, {
          gasLimit: gasEstimate.mul(2),
        });
      await withdrawTx.wait();

      alert("Withdrawal successful!");

      await loadDefaultData();
      await loadUserData();
    } catch (error) {
      console.error("Withdrawal failed:", error);
      alert("Withdrawal failed! Check the console for more details.");
    }
  };

  useEffect(() => {
    const init = async () => {
      await loadDefaultData();
      if (window.ethereum) {
        window.ethereum.on("chainChanged", () => {
          setIsLoading(true);
        });
        window.ethereum.on("accountsChanged", () => {
          setIsLoading(true);
        });
      }
      setIsLoading(false);
    };

    init();
  }, []);

  useEffect(() => {
    if (isLoading) {
      loadUserData();
    }
  }, [isLoading]);

  return (
    <Container className="mb-3">
      <Navbar className="my-4">
        <div className="flex !flex-row max-sm:!flex-col ">
          <img
            alt="BinaryBit logo"
            src={logo}
            width="250"
            height="150"
            className="mr-2 align-top d-inline-block"
          />
          <p className="flex text-[30px] justify-center text-white max-sm:text-[20px] mr-1 max-sm:!ml-0 max-sm:relative max-sm:-left-8">
            Staking
          </p>
        </div>

        <Navbar.Collapse className="justify-content-end">
          {account ? (
            <Button
              className="rounded-lg py-1.5 px-8 text-white font-bold text-sm lg:text-[20px] hover:shadow-active bg-[#047EFF] h-12"
              onClick={async () => {
                try {
                  setAccount(null);
                  setAccountWBNRYBalance(null);
                  setAccountStake(null);
                  setAccountGains(null);
                  setAccountStakeBalance(null);
                  loadDefaultData();
                } catch (error) {
                  console.error(error);
                  alert("TODO: disconnect failed");
                }
              }}
            >
              Disconnect
            </Button>
          ) : (
            <Button
              className="rounded-lg py-1.5 px-8 text-white font-bold text-sm lg:text-[20px] hover:shadow-active bg-[#047EFF] h-12"
              onClick={async () => {
                try {
                  await window.ethereum.request({
                    method: "eth_requestAccounts",
                  });
                  loadUserData();
                } catch (error) {
                  if (error.code === -32002) {
                    alert("Already processing MetaMask login. Please wait.");
                  } else {
                    console.error(error);
                  }
                }
              }}
            >
              Connect
            </Button>
          )}
        </Navbar.Collapse>
      </Navbar>

      <div className="flex flex-col gap-4">
        <section className="">
          <h2 className="text-3xl">Staking Information</h2>
          <p>Staking rewards: {annualYield}% (Based on the number of nodes operating on the network)</p>
        </section>
        <hr />{" "}
        {/* Line break to separate contract information and user information */}
        {isLoading ? (
          <div className="my-5 text-center">
            <p className="my-2">Please connect metamask...</p>
          </div>
        ) : (
          <>
            <h2 className="text-3xl">User Information</h2>
            <p>
              <strong>Wallet address: </strong>
              {account}
            </p>
            <p>
              <strong>WBNRY Owned: </strong>
              {accountWBNRYBalance}
            </p>
            <p>
              <strong>WBNRY Staked: </strong>
              {accountStake}
            </p>
            <p>
              <strong>Accumulated Staking Yield: </strong>
              {accountGains}
            </p>
            <p>
              <strong>Total Staking Balance: </strong>
              {accountStakeBalance}
            </p>
            {account && (
              <>
                <Form onSubmit={handleStake}>
                  <Form.Group controlId="stakeAmount">
                    <Form.Label>
                      Amount to Stake
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="h-12"
                      value={stakeAmount}
                      onChange={(e) => setStakeAmount(e.target.value)}
                      placeholder="Enter amount to stake"
                    />
                  </Form.Group>

                  <div className="gap-4 mt-3 d-flex align-items-center">
                    <div
                      variant="primary"
                      className="flex px-8 py-4 justify-center items-center rounded-[30px] font-bold bg-gradient-to-r from-[#4338ca] to-[#5b21b6] cursor-pointer"
                      onClick={handleStake}
                    >
                      Stake WBNRY
                    </div>
                    <div
                      variant="primary"
                      className="flex px-8 py-4 justify-center items-center rounded-[30px] font-bold bg-gradient-to-r from-[#4338ca] to-[#5b21b6] cursor-pointer"
                      onClick={handleStakeMax}
                    >
                      Stake Max WBNRY
                    </div>
                  </div>
                </Form>
                <Form onSubmit={handleWithdraw}>
                  <Form.Group controlId="withdrawAmount" className="mt-3">
                    <Form.Label>
                      Amount to Withdraw 
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={withdrawAmount}
                      className="h-12"
                      onChange={(e) => setWithdrawAmount(e.target.value)}
                      placeholder="Enter amount to withdraw"
                    />
                  </Form.Group>
                  <div className="gap-4 mt-3 d-flex align-items-center">
                    <div
                      variant="primary"
                      className="flex px-8 py-4 justify-center items-center rounded-[30px] font-bold bg-gradient-to-r from-[#4338ca] to-[#5b21b6] cursor-pointer"
                      onClick={handleWithdraw}
                    >
                      Withdraw WBNRY
                    </div>
                    <div
                      variant="primary"
                      className="flex px-8 py-4 justify-center items-center rounded-[30px] font-bold bg-gradient-to-r from-[#4338ca] to-[#5b21b6] cursor-pointer"
                      onClick={handleWithdrawMax}
                    >
                      Withdraw Max WBNRY
                    </div>
                  </div>
                </Form>
              </>
            )}
          </>
        )}
      </div>
    </Container>
  );
}

export default App;
